import { Modal, Text, Input, Badge, Button, Progress, Link, Card, Grid, Checkbox, Spacer } from "@nextui-org/react"
import { useAppSelector } from "../../shared/hooks/redux.hook";
import { formatFileSize } from "../../shared/utils/utils";
import { WarningIcon } from "../icons/warning-icon.component";
import styles from './ml-symbol-modal.module.css'
import { PlanModalComponent } from "../plan-modal/plan-modal.component";
import { useState } from "react";
import { QuantdleCodeEditor } from "../quantdle-code-editor/quantdle-code-editor.component";
import { MlCodeEditorComponent } from "./ml-code-editor.component";
import  { BsCopy } from "react-icons/bs";
import { PiDownloadSimpleBold } from "react-icons/pi";

export const MLSymbolModalComponent = (props: {visible: boolean, downloadName: string, instrument: string, fileSize: number, onClose?: () => void, onDownload?: (downloadName: string) => void }) => {

    const usage = useAppSelector(state => state.usage)
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)
    const isGreaterThanAllowed = Number(usage.bandwith) + Number(props.fileSize) > Number(usage.maxBandwith)
    const isFreeUser = usage.plan === 'free'
    const isMicroUser = usage.plan === 'micro'
    const isPlatinumUser = usage.plan === 'platinum'

    const [unzip, setupzip] = useState<boolean>(true)
    const [statistics, setStatistics] = useState<boolean>(false)
    const [plot, setPlot] = useState<boolean>(false)

    const handleOnDownload = (assetClass: string) => {
        if (props.onDownload){
            props.onDownload(assetClass)
        }
    }

    const handleOnCheckboxChange = (options: string[]) => {

        // set the states depending on the keys stored in options
        setupzip(options.includes('unzip'))
        setStatistics(options.includes('statistics'))
        setPlot(options.includes('plot'))

    }

    

    return (
        <Modal
            scroll 
            width="80%"
            closeButton
            preventClose
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={props.visible}
            onClose={props.onClose}
        >
        <Modal.Header>
            <Text h3 id="modal-title">
                {`Download ${props.instrument} ML ready data`}
            </Text>
        </Modal.Header>
        <Modal.Body>
            <Text b h3 style={{userSelect: 'none'}}>Quick Start for {props.instrument}</Text>
            <Grid xs={12} style={{display: 'flex'}}>
                <Grid xs={12} sm={6} style={{display: 'block', paddingLeft: 20, paddingRight: 20}}>
                    <ol>
                        <li>
                            <Text style={{display: 'flex', alignItems: 'flex-end'}}>
                                Download machine learning ready data by clicking 
                                <span style={{marginLeft: 5}}>
                                    <Button disabled={(isGreaterThanAllowed  || (!isPlatinumUser))} onPress={() => handleOnDownload(props.downloadName)} size={'xs'} auto color={'secondary'}>Download</Button>
                                </span> 
                            </Text>
                        </li>
                        <li>
                            <Text>Install dependencies:</Text>
                            <Spacer y={0.5}/>
                            <QuantdleCodeEditor language="bash" code="python -m pip install pandas
python -m pip install matplotlib" height={40}/>
                        </li>
                            
                        <li><Text>Customize the quickstart code snippet by selecting the desired features:</Text>
                            <div style={{marginLeft: 20, marginTop: 5}}>
                                <Checkbox.Group
                                    size="xs"
                                    orientation="horizontal"
                                    color="secondary"
                                    onChange={handleOnCheckboxChange}
                                    defaultValue={["unzip", 'dates']}
                                    >
                                    <Checkbox value="unzip">Load from .zip</Checkbox>
                                    <Checkbox value="statistics">Include statistics</Checkbox>
                                    <Checkbox value="plot">Plot data</Checkbox>
                                </Checkbox.Group>
                            </div>
                        </li>
                        <li><Text >Copy <BsCopy/> or download <PiDownloadSimpleBold /> the quick start code on the right-hand side.</Text></li>
                        <li><Text>Start coding!</Text></li>
                    </ol>
                    <Text h4 style={{userSelect: 'none'}}>Bandwidth usage after download</Text>
                    <Text color={isGreaterThanAllowed ? "error" : "secondary"} h4 css={{lineHeight: "$xs", margin: 0, display: 'flex', alignItems: 'flex-end' }}>
                        {formatFileSize(usage.bandwith, 0)} + {formatFileSize(props.fileSize, 0)} = {formatFileSize(Number(usage.bandwith) + Number(props.fileSize), 0)} <Text small css={{ color: "$accents5" }}>/ {formatFileSize(usage.maxBandwith, 0)}</Text>
                    </Text>
                    { (!isPlatinumUser) &&
                        <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none', marginTop: 15}} className={styles.warning}>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                            <Text color="currentColor"> Manual downloads are only avaiable for Platinum members ​☹️​.</Text>
                        </div>
                    }
                    {isGreaterThanAllowed && 
                        <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none', marginTop: 15}} className={styles.warning}>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                            <Text color="currentColor"> You won't be able to download this file, as it will hit your monthly bandwidth limit 😲.</Text>
                        </div>
                    }
                    {
                        (isGreaterThanAllowed  || (!isPlatinumUser)) &&
                        <>
                            <Text h4><Link color={'primary'} underline onPress={() => setShowPlanModal(true)}>Update your plan</Link><span> to use this feature! 😌​</span></Text>
                        </>
                    }
                </Grid>
                <Grid xs={12} sm={6}>
                    <MlCodeEditorComponent symbol={props.instrument} zipCode={unzip} statistics={statistics} plotData={plot} />
                </Grid>
            </Grid>
                        
        </Modal.Body>
        <Modal.Footer>
            <Button auto flat onPress={props.onClose} color="error">
                Close
            </Button>
            <Button disabled={(isGreaterThanAllowed  || (!isPlatinumUser))} onPress={() => handleOnDownload(props.downloadName)} auto>
                Download
            </Button>
        </Modal.Footer>
            <PlanModalComponent visible={showPlanModal} onClose={() => setShowPlanModal(false)}/>
        </Modal>
        
    )

}