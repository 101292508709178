import React from 'react';
import { IoHelpCircleOutline as HelpCircle } from "react-icons/io5";
import { Tooltip } from '@nextui-org/react';
import './HelpWrapper.css';

interface HelpWrapperProps {
  children: React.ReactNode;
  tooltipText: string;
  documentationLink: string;
}

export const HelpWrapper: React.FC<HelpWrapperProps> = ({
  children,
  tooltipText,
  documentationLink
}) => {
  return (
    <div className="help-wrapper">
      {children}
      <Tooltip content={tooltipText}>
        <a 
          href={documentationLink}
          target="_blank"
          rel="noopener noreferrer"
          className="help-icon"
        >
          <HelpCircle size={20} />
        </a>
      </Tooltip>
    </div>
  );
};