import { Dropdown, Text, User, useTheme } from "@nextui-org/react"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { fetchUserAttributes } from 'aws-amplify/auth'
import { useAuthenticator } from "@aws-amplify/ui-react"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"



export enum ProfileOptions {
    PROFILE = 'PROFILE',
    BILLING = 'BILLING',
    SUBSCRIPTION = "SUBSCRIPTION",
    LOGOUT = 'LOGOUT'
}

export const UserInfoRedesignComponent = () => {


    const [email, setEmail] = useState<string | undefined>()
    const [id, setId] = useState<string | undefined>()

    async function handleFetchUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            setEmail(userAttributes?.email);
            setId(userAttributes?.sub);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() =>  {
        handleFetchUserAttributes()
    }, [])

    const {theme} = useTheme()

    const imageBackgroundColor = useMemo(() =>
        theme?.colors.primary.value.split('#')[1]
    , [theme])

    const {signOut} = useAuthenticator((context) => [context.signOut]);

    const { paymentsApi } = useQuantdleApi()

    const avatarUrl = `https://ui-avatars.com/api/?background=${imageBackgroundColor}&color=fff&name=${email ?? '...'}`

    const onSelection = async (key: any) => {
        switch (key) {
            case ProfileOptions.LOGOUT:
                // logout user
                signOut()
                break;
            case ProfileOptions.SUBSCRIPTION:
                // generate the client portal session an redirect
                const toasId = toast.loading("Generating session...")
                paymentsApi.createPortalSessionGet().then(response => {
                    // open the iurl in a new tab
                    window.open(response.data.url, '_blank')
                    toast.success("Session generated", {id: toasId})
                })
                break;
            case ProfileOptions.PROFILE:
                // navigate('/profile')
                break;
            default:
                break;
        }
    }

    return (
        <Dropdown placement="bottom-left">
        <Dropdown.Trigger>
            <div style={{display: 'flex', flexDirection: 'row', padding:'5px 10px 5px 10px', cursor: 'pointer'}} className='login-button'>
                <User src={avatarUrl} name="" bordered color="primary" size="lg" style={{zIndex: -1}}/>
            </div>
        </Dropdown.Trigger>
        <Dropdown.Menu
            aria-label="User Actions"
            onAction={(key) => onSelection(key)}
            disabledKeys={[ProfileOptions.BILLING]}
        >
            <Dropdown.Item  key={ProfileOptions.PROFILE} css={{ height: "$18" }} textValue='profile'>
                <Text b color="inherit" css={{ d: "flex", fd:"column" }}>
                    <span>Identified as</span>
                    <span style={{color:"var(--nextui-colors-primary)"}}>{email}</span>
                </Text>
                <Text small  css={{ d: "flex" }}>
                    <span style={{color:"var(--nextui-colors-accents7)"}}>{id}</span>
                </Text>
            </Dropdown.Item>
            <Dropdown.Item key={ProfileOptions.SUBSCRIPTION} textValue="Subscription" withDivider>
                Subscription Settings
            </Dropdown.Item>
            <Dropdown.Item key={ProfileOptions.LOGOUT} textValue="logout" color="error" withDivider>
                Log Out
            </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
    )

}
